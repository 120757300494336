import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fFormatDate } from '../../utils/formatTime';
import axios from '../../utils/axios';
const initialState = {
    status: 'idle',
    error: null,
    seasons: [],
    message: '',
    season: null,
    meta: { currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0 },
};
export const getSeasons = createAsyncThunk('season/getSeasons', async ({ page, rowsPerPage, orderBy = 'createdAt', order = 'desc', filterName = '', filterEndDate = '', filterStartDate = '', isPaging = true, } = {}) => {
    try {
        let url = `/seasons?isPaging=${isPaging}`;
        if (page !== undefined && rowsPerPage !== undefined) {
            url += `&page=${page + 1}&perPage=${rowsPerPage}&orderBy=${orderBy}&sortedBy=${order}`;
        }
        const start = filterEndDate && filterEndDate[0] !== '' && filterEndDate[1] !== ''
            ? `endDate:${fFormatDate(filterEndDate[0])},${fFormatDate(filterEndDate[1])};`
            : '';
        const end = filterStartDate && filterStartDate[0] !== '' && filterStartDate[1] !== ''
            ? `startDate:${fFormatDate(filterStartDate[0])},${fFormatDate(filterStartDate[1])};`
            : '';
        const search = start + end;
        if (search) {
            url = `/seasons?search=${search}`;
        }
        if (!search && filterName && filterName !== undefined) {
            url += `&search=name:${filterName};&searchJoin=OR`;
        }
        const response = await axios.get(url);
        const data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : 'something went wrong');
    }
});
export const getSeason = createAsyncThunk('season/getSeason', async (id) => {
    try {
        const response = await axios.get(`/seasons/${id}`);
        const { data } = await response.data;
        if (response.status === 200) {
            return { data };
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message);
    }
});
export const deleteSeason = createAsyncThunk('season/deleteSeason', async ({ id, getParams }, thunkAPI) => {
    try {
        const response = await axios.delete(`/seasons/${id}`);
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getSeasons({ ...getParams }));
            return { message: 'Season has been deleted successfully !' };
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const deleteManySeasons = createAsyncThunk('season/deleteManySeasons', async ({ ids, getParams }, thunkAPI) => {
    try {
        const response = await axios.delete(`/seasons/many`, { data: { ids } });
        if (response.status === 200) {
            thunkAPI.dispatch(getSeasons({ ...getParams }));
            return { message: 'Seasons deleted successfully!' };
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const createSeason = createAsyncThunk('season/createSeason', async (Data) => {
    let data;
    try {
        const obj = {
            description: Data.description,
            endDate: Data.endDate,
            minDay: Data.minDay,
            name: Data.name,
            occurence: Data.occurence,
            startDate: Data.startDate,
        };
        if (obj.occurence === '')
            delete obj.occurence;
        const response = await axios.post(`/seasons`, obj);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message);
    }
});
export const updateSeason = createAsyncThunk('season/updateSeason', async (season) => {
    let data;
    const obj = {
        description: season.description,
        endDate: season.endDate,
        minDay: season.minDay,
        name: season.name,
        occurence: season.occurence ? season.occurence : null,
        startDate: season.startDate,
    };
    try {
        const response = await axios.put(`/seasons/${season?.id}`, obj);
        data = await response.data;
        if (response.status === 200) {
            return {
                message: 'Season has been updated successfully !',
            };
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
const slice = createSlice({
    name: 'season',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSeasons.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getSeasons.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.seasons = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getSeasons.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getSeason.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getSeason.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.season = action.payload.data;
        })
            .addCase(getSeason.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(createSeason.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(createSeason.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(createSeason.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice;
